import {validators} from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "name",
      model: "name",
      required: true,
      placeholder: "name",
      max: 256,
      min: 4,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("nameRequired"),
          textTooSmall: i18n.t("nameMustBeMin{1}"),
          textTooBig: i18n.t("nameMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "token",
      model: "token",
      required: true,
      placeholder: "token",
      max: 256,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("tokenRequired"),
          textTooBig: i18n.t("tokenMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "checkbox",
      label: "allowTree",
      model: "allowTree",
      required: true,
    },
    {
      type: "checkbox",
      label: "allowSeo",
      model: "allowSeo",
      required: true,
    },
    {
      type: "checkbox",
      label: "isRequired",
      model: "isRequired",
      required: true,
    },
    {
      type: "input",
      inputType: "text",
      label: "iconClass",
      model: "iconClass",
      required: true,
      placeholder: "iconClass",
      max: 25,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("iconClassRequired"),
          textTooBig: i18n.t("iconClassMustBeLess{1}"),
        }),
      ]
    },
  ]
};
